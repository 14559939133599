import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from "aos";

import Bride from '@/modules/contact/modules/bride /index.vue'
import Salon from '@/modules/contact/modules/salon/index.vue'
//sections
export default {
    name: "contact",
    components: {
        Bride,
        Salon
    },
    watch: {},
    data() {
        return {
            selectBride: true,
            selectSalon: false
        }
    },
    created() {
        this.getSocials()
    },
    computed: {
        ...mapGetters({
            socials: 'contact/socials'
        }),
    },
    mounted() {
        AOS.init({
            disable: function () {
                const maxWidth = 700;
                return window.innerWidth <= maxWidth;
            }
        });
    },
    methods: {
        ...mapActions({
            getSocials: 'contact/GET_SOCIALS'
        }),
        selectForm() {
            switch (this.selectBride) {
                case true:
                    this.selectSalon = true
                    this.selectBride = false
                    break;
                case false:
                    this.selectSalon = false
                    this.selectBride = true
                    break;
            }
        }
    }
}
