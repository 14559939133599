import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';
import mainSelect from '@/components/main-select/index.vue';

import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
import AOS from "aos";

//sections
export default {
    name: "bride",
    mixins: [validationMixin],
    components: {
        mainButton,
        mainSelect
    },
    watch: {},
    data() {
        return {
            showErrors: true,
            payload: {
                name: '',
                email: '',
                city: '',
                country: '',
                message: ''
            },
            validationErrors: {},

        }
    },
    validations: {
        payload: {
            name: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2),
            },
            email: {
                email,
                maxLength: maxLength(30),
                required,
            },
            city: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(2),
            },
            message: {
                required,
                maxLength: maxLength(199),
                minLength: minLength(10),
            },
            country: {
                required,
            },

        },
    },
    created() {
        this.getCountries()
    },
    computed: {
        ...mapGetters({
            countries: 'contact/countries',
            message: 'contact/brideData',
            loading: 'contact/brideLoading'
        }),
        firstNameError() {
            const error = []
            if (!this.$v.payload.name.$dirty) {
                return error
            }
            if (!this.$v.payload.name.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.name.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.name.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.name) {
                this.validationErrors.name.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        emailError() {
            const error = []
            if (!this.$v.payload.email.$dirty) {
                return error
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('errorEmail'))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        cityError() {
            const error = []
            if (!this.$v.payload.city.$dirty) {
                return error
            }
            if (!this.$v.payload.city.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.city.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.city.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 8))
            }
            if (this.validationErrors.city) {
                this.validationErrors.city.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        countryError() {
            const error = []
            if (!this.$v.payload.country.$dirty) {
                return error
            }
            if (!this.$v.payload.country.required) {
                error.push(this.$t('errorRequired'))
            }
            if (this.validationErrors.country) {
                this.validationErrors.country.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        messageError() {
            const error = []
            if (!this.$v.payload.message.$dirty) {
                return error
            }
            if (!this.$v.payload.message.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.message.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.message.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 2))
            }
            if (this.validationErrors.message) {
                this.validationErrors.message.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
    },
    mounted() {
        AOS.init({
            disable: function () {
                const maxWidth = 700;
                return window.innerWidth <= maxWidth;
            }
        });
    },
    methods: {
        ...mapActions({
            getCountries: 'contact/GET_CONTACT_COUNTRY',
            sendBrideData: 'contact/SET_BRIDE_DATA'
        }),
        submitForm() {
            this.$v.$touch();
            if (!this.$v.payload.$invalid) {

                let obj = {}
                obj.country = this.payload.country.title
                let data = Object.assign(this.payload, obj)

                this.sendBrideData(data).then(() => {

                    this.$toasted.success(this.$t('contact.salonSuccessMessage'));
                    this.$v.$reset();
                    this.payload = {
                        name: '',
                        email: '',
                        city: '',
                        country: '',
                        message: ''
                    }

                }).catch(error => {
                    if (error.status === 422) {
                        this.$toasted.error(this.$t('contact.salonErrorMessage'));
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                })
            }
        }
    }
}




